<template>
  <div class="table">
    <router-view/>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  setup() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
</style>
